import { render, staticRenderFns } from "./SocialUserAlterarUsuario.vue?vue&type=template&id=41068896&scoped=true"
import script from "./SocialUserAlterarUsuario.vue?vue&type=script&lang=js"
export * from "./SocialUserAlterarUsuario.vue?vue&type=script&lang=js"
import style0 from "./SocialUserAlterarUsuario.vue?vue&type=style&index=0&id=41068896&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41068896",
  null
  
)

export default component.exports